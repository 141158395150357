import React from "react";
import "./slides.css";

const Metabolomics = (slides) => {
  const scienceOne = slides.data.slides[6].info1;
  const scienceTwo = slides.data.slides[6].info2;
  const scienceThree = slides.data.slides[6].info3;
  const scienceFour = slides.data.slides[6].info4;
  const scienceFive = slides.data.slides[6].info5;

  const petOne = slides.data.slides[7].info1;
  const petTwo = slides.data.slides[7].info2;
  const petThree = slides.data.slides[7].info3;
  const petFour = slides.data.slides[7].info4;
  const footnote = slides.data.slides[7].footnote;

  const handleClick = () => {
    const primarySlide = document.getElementById("primary");
    const secondarySlide = document.getElementById("secondary");
    primarySlide.classList.toggle("hide");
    primarySlide.classList.toggle("is-visible");
    secondarySlide.classList.toggle("hide");
    secondarySlide.classList.toggle("is-visible");
  };
  return (
    <div className="page-container">
      <div className="media-container is-visible" id="primary">
        <div className="list-container">
          <ul className="data-list">
            <li className="list-item" id="list-item-one">
              {scienceOne}
            </li>
            <li className="list-item" id="list-item-two">
              {scienceTwo}
            </li>
            <li className="list-item" id="list-item-three">
              {scienceThree}
            </li>
            {scienceFour ? (
              <li className="list-item" id="list-item-four">
                {scienceFour}
              </li>
            ) : null}
            {scienceFive ? (
              <li className="list-item" id="list-item-five">
                {scienceFive}
              </li>
            ) : null}
          </ul>
        </div>
        <div
          className="science-image-container"
          style={{
            backgroundImage: `url("https://storage.googleapis.com/purina-virtual-experience/06-future-of-nutrition/images/PTC0701_Purina_FutureOfNutrition_Metabolide_3FX17.jpg")`,
          }}
        >
          <div className="molecule-label">
            <h3>METABOLITES</h3>
          </div>
          <button className="switch-panel right" onClick={handleClick}>
            <img
              src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Right%20Arrow.png"
              alt="next page"
            />
          </button>
        </div>
      </div>
      <div className="media-container" id="secondary">
        <button className="switch-panel left" onClick={handleClick}>
          <img
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Left%20Arrow.png"
            alt="previous page"
          />
        </button>
        <div className="list-container">
          <ul className="data-list">
            <li className="list-item" id="list-item-one">
              {petOne}
            </li>
            <li className="list-item" id="list-item-two">
              {petTwo}
            </li>
            <li className="list-item" id="list-item-three">
              {petThree}
            </li>
            {petFour ? (
              <li className="list-item" id="list-item-four">
                {petFour}
              </li>
            ) : null}
          </ul>
          <p id="footnote">{footnote}</p>
        </div>
        <div className="hero-image-container">
          <img
            className="hero-image"
            src="https://storage.googleapis.com/purina-virtual-experience/06-future-of-nutrition/images/06-metabolomics-peyton-dog.png"
            alt="peyton the dog"
          />
        </div>
      </div>
    </div>
  );
};

export default Metabolomics;
